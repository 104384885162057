import React from 'react';
// import Image from '../assets/images/image.jpg';
import ServicesCard from '../components/ServicesCard';
import { useSpring, animated } from 'react-spring';
// import Image1 from '../assets/images/image1.jpg';
import Image2 from '../assets/images/image2.jpg';
import Image3 from '../assets/images/image3.jpg';
import ImageH from '../assets/images/imageH.jpg';
import production from '../assets/images/production.jpg';
import sale from '../assets/images/sale.jpg';
import { Link } from 'react-router-dom';

export default function Services() {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  return (
    <div className='px-3 lg:px-8 pb-4 md:pb-8 bg-gray-200'>
      <div className='relative h-28 md:h-60 bg-black -mx-4 lg:-mx-12 grid place-content-center space-y-2 md:space-y-4'>
        {/* <img src={Image} alt="" className='absolute h-full' /> */}
        <h1 className='text-white lg:text-4xl z-10'>Our Services</h1>
        <p className='text-white'>
          <Link to='/' className='text-white hover:underline'>Home</Link> &#129174; <span className='text-pink'>Services</span> 
        </p>
        <p className='text-white'>Central Roofing - Your One-Stop Roofing Hub!</p>
      </div>
      <h1 className='my-4 md:my-8 font-bold text-xl md:text-2xl lg:text-4xl text-start text-black'>Our Services</h1>
      <p className='text-start text-gray-700'>
        Central Roofing Systems is your trusted partner for top-quality roofing solutions at unbeatable prices. 
        We specialize in manufacturing and expertly installing a wide range of roofing materials, including durable aluminum roofing sheets, 
        elegant coated tiles, reliable Aluzinc roofing sheets, efficient PVC rain gutters, and stylish shingles. 
        With our commitment to excellence, you can be confident in the longevity and beauty of your roof. 
        Contact us today for exceptional roofing services that stand the test of time.
      </p>
      <animated.div style={fade} className='grid md:grid-cols-6 gap-3 mt-4 text-gray-700'>
        <ServicesCard
          title='Production of roofing sheets'
          description="
            Roofing sheets are commonly made from materials like aluminium and alu-zinc metals.
            The production process involves running the materials into the machine to form a wave-like structure.
            Every type of roofing sheet has it's own machine that moulds them into their own beautiful form.
          "
          image={production}
          className={`col-span-2 md:col-span-3 lg:col-span-2`}
        />
        <ServicesCard
          title='Installation of roofing sheets'
          description="
            Installation of roofing sheets involves different steps. First, you have to measure and cut the sheets to fit the roof,
            then you place the sheets on the roof and secure them with nails or screws starting from the bottom and work your
            way up. It's important to overlap the sheets to prevent leaks. It's a job that requires a lot of care and precision
            to ensure a good job.
          "
          image={Image3}
          className={`col-span-2 md:col-span-3 lg:col-span-2`}
        />
        <ServicesCard
          title='Free estimation'
          description="
            Free estimation is a service we offer to potential clients. It involves assessing the scope of the project,
            like the size of the roof, and providing a cost estimate for the work. This usually includes labour, materials,
            and other expenses. The goal is to give the client an idea of what to expect before they commit to the project.
          "
          image={ImageH}
          className={`col-span-2 md:col-span-3 lg:col-span-2`}
        />
        <ServicesCard
          title='Sales of roofing sheets'
          description="
            Sales of roofing sheets involves marketing and distributing the sheets to customers. This can be done through
            a physical store, online store, or both. The seller needs to understand the different types of roofing sheets
            available, their applications, and their advantages and disadvantages to effectively guide customers. Pricing
            can depend on the material, size, and type of the sheet. After-sales service, like installation guidance or warranties,
            can also be part of the sales process.
          "
          image={sale}
          className={`col-span-2 md:col-span-3 lg:col-span-3`}
        />
        <ServicesCard
          title='Repairs and re-roofing of roofing sheets'
          description="
            Repairs and re-roofing involves fixing or replacing damaged sheets. Repairs might be needed for
            small issues like leaks, rust, or loose sheets. If the damage is extensive or the roof is old
            re-roofing might be necessary. This involves removing the old roofing sheets and installing new ones.
            Both tasks require knowledge of roofing installation and safety measures.
          "
          image={Image2}
          className={`col-span-2 md:col-span-6 lg:col-span-3`}
        />
      </animated.div>
      {/* <div className='grid md:grid-cols-2 md:grid-row-3 gap-3 mt-4 '>
        <div className='row-span-3 overflow-hidden rounded-lg shadow-lg transition-all duration-500 ease-in-out transform'>
          <img src={ImageH} alt="" className='w-full h-full object-cover hover:opacity-80 hover:scale-110 transition-all duration-500 ease-in-out transform' />
        </div>
        <div className='col-span-1 overflow-hidden rounded-lg shadow-lg transition-all duration-500 ease-in-out transform'>
          <img src={Image1} alt="" className='w-full h-full object-cover hover:opacity-80 hover:scale-110 transition-all duration-500 ease-in-out transform' />
        </div>
        <div className='col-span-1 overflow-hidden rounded-lg shadow-lg transition-all duration-500 ease-in-out transform'>
          <img src={Image2} alt="" className='w-full h-full object-cover hover:opacity-80 hover:scale-110 transition-all duration-500 ease-in-out transform' />
        </div>
        <div className='col-span-1 overflow-hidden rounded-lg shadow-lg transition-all duration-500 ease-in-out transform'>
          <img src={Image3} alt="" className='w-full h-full object-cover hover:opacity-80 hover:scale-110 transition-all duration-500 ease-in-out transform' />
        </div>
      </div> */}
    </div>
  )
}