import React from 'react';
import { Link } from 'react-router-dom';
import project1 from '../assets/images/project1.jpg';
import project2 from '../assets/images/project2.jpeg';
import project3 from '../assets/images/project3.jpeg';
import project4 from '../assets/videos/project4.mp4';
import project5 from '../assets/videos/project5.mp4';
import project6 from '../assets/videos/project6.mp4';

export default function Projects() {
  return (
    <div className='px-3 lg:px-8 pb-4 md:pb-8 bg-gray-200'>
      <div className='h-28 md:h-60 bg-black -mx-4 lg:-mx-12 grid place-content-center space-y-2 md:space-y-4'>
        <h1 className='text-white lg:text-4xl'>Our Projects</h1>
        <p className='text-white'>
          <Link to='/' className='text-white hover:underline'>Home</Link> &#129174; <span className='text-pink'>Projects</span> 
        </p>
        <p className='text-white'>Central Roofing - Your One-Stop Roofing Hub!</p>
      </div>
      <div className='my-8 md:my-16'>
        <h1 className='my-4 md:my-8 font-bold text-xl md:text-2xl lg:text-4xl text-start text-black'>Our Projects</h1>
        <p className='text-start text-gray-700'>
          Need a reliable roofing company? Look no further than Central Roofing! Our experienced team is dedicated 
          to providing top-notch service and quality workmanship for all your roofing needs. 
          Trust us to keep your home or business safe and secure. Contact us today for a free estimate!
        </p>
      </div>
      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10 mb-8'>
        <img src={project1} alt="" />
        <img src={project2} alt="" />
        <img src={project3} alt="" />
        <video loop autoPlay muted src={project4}></video>
        <video loop autoPlay muted src={project5}></video>
        <video loop autoPlay muted src={project6}></video>
      </div>
    </div>
  )
}