import React from 'react';
import { Link } from 'react-router-dom';
import centralRoofingVideo from '../assets/videos/centralRoofingVid.mp4';

export default function About() {
  return (
    <div className='px-3 lg:px-8 bg-gray-200'>
      <div className='relative h-28 md:h-60 bg-black -mx-4 lg:-mx-12 grid place-content-center space-y-2 md:space-y-4'>
        {/* <img src={Image} alt="" className='absolute h-full' /> */}
        <h1 className='text-white lg:text-4xl z-10'>About Company</h1>
        <p className='text-white z-10'>
          <Link to='/' className='hover:underline'>Home</Link> &#129174; <span className='text-pink'>About</span> 
        </p>
        <p className='text-white'>Central Roofing - Your One-Stop Roofing Hub!</p>
      </div>
      <div className='md:flex my-8 lg:my-16'>
        <div className='md:w-1/2'>
          <h1 className='mb-4 md:mb-8 font-bold text-xl md:text-2xl lg:text-4xl text-start text-black'>About Company</h1>
          <p className='text-start mb-4 md:mb-8 text-gray-700'>
            Central Roofing , based in the heart of Breku-Akoti, Central Region, Ghana, is a leading provider of a high quality
            roofing solutions. We specialize in the manufacturing and installation of durable roofing sheets designed to withstand
            diverse weather conditions. Our commitment to excellence, coupled with our local understanding, allow us to create roofing
            systems that not only protect but also enhance the beauty of your structures. Central roofing, your one stop roofing hub. 
          </p>
          <ul className="text-start mb-4 md:mb-8 text-gray-700">
            <li>&#8227; Over 1.5k Partners have worked with us.</li>
            <li>&#8227; Professional and experienced human resources.</li>
            <li>&#8227; Provide the best roof services.</li>
            <li>&#8227; We have more than 10 years of experiences.</li>
            <li>&#8227; We use professional and experienced person.</li>
          </ul>
        </div>
        <div className='md:w-1/2 grid justify-end'>
          <video autoPlay loop muted className='w-full md:h-96' src={centralRoofingVideo}></video>
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 pb-8 lg:pb-16">
        <div className='bg-blue-700 h-80 lg:h-96'>
          <p className='m-4 lg:m-8 p-4 bg-white text-gray-700 h-12 w-12 md:h-16 md:w-16 rounded-full grid place-content-center font-bold text-2xl md:text-3xl lg:text-5xl'>1</p>
          <h1 className='text-start font-bold text-xl md:text-2xl my-2 px-4 lg:px-8 mb-4 lg:mb-8'>Plan for Roofing</h1>
          <p className='text-start text-white px-4 lg:px-8'>Our roofing process begins with meticulous planning to ensure every project's success.</p>
        </div>
        <div className='bg-blue-600 h-80 lg:h-96'>
          <p className='m-4 lg:m-8 p-4 bg-white text-gray-700 h-12 w-12 md:h-16 md:w-16 rounded-full grid place-content-center font-bold text-2xl md:text-3xl lg:text-5xl'>2</p>
          <h1 className='text-start font-bold text-xl md:text-2xl my-2 px-4 lg:px-8 mb-4 lg:mb-8'>Schedule Estimate</h1>
          <p className='text-start text-white px-4 lg:px-8'>We provide a detailed estimate and schedule to keep our clients informed at every step.</p>
        </div>
        <div className='bg-blue-500 h-80 lg:h-96'>
          <p className='m-4 lg:m-8 p-4 bg-white text-gray-700 h-12 w-12 md:h-16 md:w-16 rounded-full grid place-content-center font-bold text-2xl md:text-3xl lg:text-5xl'>3</p>
          <h1 className='text-start font-bold text-xl md:text-2xl my-2 px-4 lg:px-8 mb-4 lg:mb-8'>Install New Roof</h1>
          <p className='text-start text-white px-4 lg:px-8'>Our skilled team efficiently installs the new roofing system with precision.</p>
        </div>
        <div className='bg-blue-400 h-80 lg:h-96'>
          <p className='m-4 lg:m-8 p-4 bg-white text-gray-700 h-12 w-12 md:h-16 md:w-16 rounded-full grid place-content-center font-bold text-2xl md:text-3xl lg:text-5xl'>4</p>
          <h1 className='text-start font-bold text-xl md:text-2xl my-2 px-4 lg:px-8 mb-4 lg:mb-8'>Enjoy Roofing</h1>
          <p className='text-start text-white px-4 lg:px-8'>Sit back and enjoy the benefits of our durable and quality roofing solutions.</p>
        </div>
      </div>
    </div>
  )
}