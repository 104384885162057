import './App.css';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Projects from './pages/Projects';

function App() {
  const Layout = () => (
    <div className="overflow-x-hidden">
      <NavBar />
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );

  const BrowserRouter = () => (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="services" element={<Services />} />
          <Route path="projects" element={<Projects />} />
        </Route>
      </Routes>
    </Router>
  );



  return (
    <div className="App">
      <BrowserRouter />
    </div>
  );
}

export default App;
