import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

export default function ServicesCard({ title, description, className, image }) {
  const [isHovered, setIsHovered] = useState(false);

  const cardAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 300, friction: 10 },
  });

  const overlayAnimation = useSpring({
    // height: isHovered ? '100%' : '0%',
    opacity: isHovered ? 1 : 0,
    config: { tension: 300, friction: 10 },
  });

  const imageAnimation = useSpring({
    opacity: isHovered ? 0.2 : 1,
    config: { tension: 300, friction: 10 },
  });

  return (
    <animated.div
      style={cardAnimation}
      className={`relative w-full h-96 overflow-hidden bg-white rounded-lg shadow-lg ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <animated.div
        style={overlayAnimation}
        className="absolute w-full h-96 top-0 left-0 right-0 bottom-0 bg-black text-white p-2 md:p-3 text-left overflow-hidden transition-all ease-in-out duration-300"
      >
        <h3 className="text-xl lg:text-3xl font-bold mb-2">{title}</h3>
        <p className="text-justify">{description}</p>
      </animated.div>
      <animated.img 
        src={image} 
        alt="" 
        className="w-full h-full object-cover object-center" 
        style={imageAnimation} 
      />
      <div
        className={`absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center items-center transition-opacity ${
          isHovered ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <h3 className="text-3xl md:text-4xl font-bold m-4 text-white">{title}</h3>
      </div>
    </animated.div>
  );
}
