import React from 'react';
// import centralRoofingVid from '../assets/videos/centralRoofingVid.mp4';
import centralRoof from '../assets/videos/centralRoof.mp4';
import centralRoofing from '../assets/videos/centralRoofing.mp4';
import { animated } from 'react-spring';
import roof from '../assets/images/roof.jpg';
import roof1 from '../assets/images/roof1.jpg';
import roof2 from '../assets/images/roof2.jpg';
import roof3 from '../assets/images/roof3.jpg';
import roof4 from '../assets/images/roof4.jpg';
import FAQSection from '../components/FAQSection';
import Carousel from '../components/Carousel';

export default function Home() {

  return (
    <div className='top-0 left-0 lg:-mt-24 overflow-x-hidden bg-white'>
      <div className='w-full relative'>
        <Carousel />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="grid">
          <p className="absolute top-[30%] bottom-[30%] lg:top-[40%] lg:bottom-[40%] text-3xl md:text-6xl lg:text-8xl font-bold px-4 text-white place-self-center shadow-2xl">
            Welcome to Central Roofing
          </p>
        </div>
      </div>
      <div className='w-full'>

        {/* Home page description */}
        <div className='px-3 lg:px-8 -mx-4 lg:-mx-12'>
          <div className='md:flex md:space-x-4 pt-8 lg:pt-16 '>
            <div className='space-y-4 md:space-y-8 mx-4 lg:mx-12 grid content-center'>
              <animated.div className={``}>
                <h1 className="text-xl md:text-2xl lg:text-4xl font-bold text-black text-center">
                  <span className='text-pink'>Central Roofing</span> is your trusted partner for top-quality roofing solutions at unbeatable prices.
                </h1>
              </animated.div>
              <p className='text-gray-700 text-justify lg:text-center'>
                Central Roofing is your trusted partner for top-quality roofing solutions at unbeatable prices. 
                We specialize in manufacturing and expertly installing a wide range of roofing materials, including durable aluminum roofing sheets, 
                elegant coated tiles, reliable Aluzinc roofing sheets, efficient PVC rain gutters, and stylish shingles. With our commitment to excellence, 
                you can be confident in the longevity and beauty of your roof. Contact us today for exceptional roofing services that stand the test of time.
              </p>
            </div>
            {/* <video autoPlay loop muted className='w-full md:h-96 mt-4 md:pr-4 lg:pr-12' src={centralRoofingVid}></video> */}
          </div>
        </div>

        {/* Working Plan */}
        <div className='px-3 lg:px-8 my-8 lg:my-16 bg-gray-100'>
          <div className='w-full'>
            <p className='text-center text-4xl lg:text-7xl font-extrabold text-pink'>Working Plan</p>
            <h1 className='text-center text-2xl mt-4 text-gray-700'>Roof Plan Working Process</h1>
          </div>
          <div className='grid grid-cols-2 grid-rows-2 lg:grid-cols-4 gap-4 lg:gap-1 place-items-end mt-4 lg:mt-8'>
            <div className='grid lg:h-72 content-start'>
              <div className='lg:w-72 lg:h-84 bg-white rounded'>
                <p className='h-10 w-10 md:h-12 md:w-12 rounded rounded-br-full rounded-bl-full rounded-tr-full grid place-content-center bg-pink text-start text-white font-bold text-2xl lg:text-4xl'>1</p>
                <img className='lg:w-full lg:h-72 object-contain rounded' src={roof4} alt="" />
              </div>
              <h1 className='text-start font-bold text-lg md:text-xl my-2 text-gray-700'>Plan for Roofing</h1>
              <p className='text-start text-gray-500 lg:pr-2'>Our roofing process begins with meticulous planning to ensure every project's success.</p>
            </div>
            <div className='grid lg:h-72 content-start'>
              <div className='lg:w-72 lg:h-84 bg-white rounded'>
                <p className='h-10 w-10 md:h-12 md:w-12 rounded rounded-br-full rounded-bl-full rounded-tr-full grid place-content-center bg-pink text-start text-white font-bold text-2xl lg:text-4xl'>2</p>
                <img className='lg:w-full lg:h-72 object-contain rounded' src={roof1} alt="" />
              </div>
              <h1 className='text-start font-bold text-lg md:text-xl my-2 text-gray-700'>Schedule Estimate</h1>
              <p className='text-start text-gray-500 lg:pr-2'>We provide a detailed estimate and schedule to keep our clients informed at every step.</p>
            </div>
            <div className='grid lg:h-72 content-start'>
              <div className='lg:w-72 lg:h-84 bg-white rounded'>
                <p className='h-10 w-10 md:h-12 md:w-12 rounded rounded-br-full rounded-bl-full rounded-tr-full grid place-content-center bg-pink text-start text-white font-bold text-2xl lg:text-4xl'>3</p>
                <img className='lg:w-full lg:h-72 object-contain rounded' src={roof3} alt="" />
              </div>
              <h1 className='text-start font-bold text-lg md:text-xl my-2 text-gray-700'>Install New Roof</h1>
              <p className='text-start text-gray-500 lg:pr-2'>Our skilled team efficiently installs the new roofing system with precision.</p>
            </div>
            <div className='grid lg:h-72 content-start'>
              <div className='lg:w-72 lg:h-84 bg-white rounded'>
                <p className='h-10 w-10 md:h-12 md:w-12 rounded rounded-br-full rounded-bl-full rounded-tr-full grid place-content-center bg-pink text-start text-white font-bold text-2xl lg:text-4xl'>4</p>
                <img className='lg:w-full lg:h-72 object-contain rounded' src={roof2} alt="" />
              </div>
              <h1 className='text-start font-bold text-lg md:text-xl my-2 text-gray-700'>Enjoy Roofing</h1>
              <p className='text-start text-gray-500 lg:pr-2'>Sit back and enjoy the benefits of our durable and quality roofing solutions.</p>
            </div>
          </div>
        </div>

        {/* videos */}
        <div className='lg:flex w-full'>
          <video className='lg:w-1/2' loop autoPlay muted src={centralRoof}></video>
          {/* <video className='lg:w-1/3' loop autoPlay muted src={centralRoofingVid}></video> */}
          <video className='lg:w-1/2' loop autoPlay muted src={centralRoofing}></video>
        </div>

        {/* 3D Image with the slogan */}
        <div className="mt-4 lg:mt-16 mb-8 lg:mb-12 flex flex-col md:flex-row justify-center items-center">
          <div className='md:w-1/2'>
            <img src={roof} alt="" />
          </div>
          <div className="md:w-1/2 gap-8">
            <div className="flex-1">
              <p className="text-4xl lg:text-7xl font-extrabold text-pink">Central Roofing</p>
              <h1 className="text-2xl mt-4 text-gray-700">Your One-Stop Roofing Hub!</h1>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div className="bg-gray-100 py-8 lg:py-16 -mx-4 md:-mx-12 px-3">
          <FAQSection />
        </div>
      </div>
    </div>
  )
}