import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebook,
  faInstagram,
  faTiktok,
  faLinkedin,
  faMedium,
  faYoutube,
  faThreads,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import moment from 'moment/moment';
import centralRoofing from '../assets/images/centralRoofing.png';

export default function Footer() {
  return (
    <footer className='bg-white bottom-0 left-0 w-full text-seablue px-3 lg:px-8 py-8'>
      {/* Top footer */}
      <div className=''>
        <div className='grid md:flex justify-between md:items-center space-y-8 md:space-x-0'>
          <div className='flex gap-5 md:gap-10'>
            <Link to='/'>
              <img src={centralRoofing} alt="Central Roofing Company Logo" className="w-24 md:w-32" />
            </Link>
          </div>
          <div className='flex gap-5 text-sm md:text-base'>
            <Link to='/' className=''>
              <p className=''>Home</p>
            </Link>
            <Link to='/about' className=''>
              <p className=''>About Us</p>
            </Link>
            <Link to='/contact' className=''>
              <p className=''>Contact Us</p>
            </Link>
            <Link to='/services' className=''>
              <p className=''>Services</p>
            </Link>
            <Link to='/projects' className=''>
              <p className=''>Projects</p>
            </Link>
          </div>
        </div>
        <div className='grid md:justify-items-center'>
          <div className='flex space-x-7 pt-8'>
            <a href='https://web.facebook.com/centralroofingghana ' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faFacebook} className='' />
            </a>
            <a href='https://www.instagram.com/centralroofingghana' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faInstagram} className='' />
            </a>
            <a href='https://www.tiktok.com/@centralroofingghana' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faTiktok} className='' />
            </a>
            <a href='https://www.linkedin.com/company/central-roofing-ghana' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faLinkedin} className='' />
            </a>
            <a href='https://medium.com/@centralroofingghana' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faMedium} className='' />
            </a>
            <a href='https://www.youtube.com/@CentralRoofing-Ghana' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faYoutube} className='' />
            </a>
            <a href='https://www.threads.net/@centralroofingghana' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faThreads} className='' />
            </a>
            <a href='https://twitter.com/centralroofgh ' target='_blank' rel='noopener noreferrer' className='hover:text-seablue text-pink md:border-2 md:rounded-full md:p-2 md:w-8 md:h-8 flex justify-center items-center'>
              <FontAwesomeIcon icon={faXTwitter} className='' />
            </a>
          </div>
        </div>
      </div>

      <hr className='border-seablue my-8'/>

      {/* Bottom footer */}
      <div className='grid justify-center md:flex md:justify-between text-sm md:text-base'>
        <p className='mb-8 md:mb-0'>© Copyright {moment().format('YYYY')}, Central Roofing</p>
        <p>
          Developed by <a href='https://eitghana.com' target='_blank' rel='noopener noreferrer' className='text-seablue hover:text-pink'>EIT Ghana Limited</a>
        </p>
      </div>
    </footer>
  )
}
