import React, { useRef} from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faSquarePhone } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm('service_c5rwehh', 'template_d60xzdl', form.current, 'HHl7u0PCJkEopwy4g')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      alert('Message sent successfully!');
      form.current.reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='px-3 lg:px-8 pb-4 md:pb-8 bg-gray-100'>
      <div className='h-28 md:h-60 bg-black -mx-4 lg:-mx-12 grid place-content-center space-y-2 md:space-y-4'>
        <h1 className='text-white lg:text-4xl'>Contact Us</h1>
        <p className='text-white'>
          <Link to='/' className='text-white hover:underline'>Home</Link> &#129174; <span className='text-pink'>Contact</span> 
        </p>
        <p className='text-white'>Central Roofing - Your One-Stop Roofing Hub!</p>
      </div>

      {/* Contact Us */}
      <div className=''>
        <h1 className='my-4 md:my-8 font-bold text-xl md:text-2xl lg:text-4xl text-start text-black'>Contact Us</h1>
        <p className='text-start text-gray-700'>
          Need a reliable roofing company? Look no further than Central Roofing! Our experienced team is dedicated 
          to providing top-notch service and quality workmanship for all your roofing needs. 
          Trust us to keep your home or business safe and secure. Contact us today for a free estimate!
        </p>
      </div>
      {/* Contact Info */}
      <section className='xl:flex lg:justify-between my-8 lg:my-8'>
        <div className='grid grid-cols-1 gap-4 md:gap-6'>
          <div className=''>
            <h1 className='font-bold text-lg md:text-xl lg:text-3xl text-left mb-2 text-black'>Contact Info</h1>
            <div className='md:flex md:justify-between lg:space-x-28'>

              {/* Contact */}
              <div>
                {/* Contact Address */}
                <div className='text-start text-gray-700 mb-3'>
                  <span className='font-bold text-lg md:text-xl lg:text-3xl'>Address:</span><br/><br/>
                  <p className='mb-2'>
                    <b>MAIN OFFICE:</b><br/> 
                    Accra<br/>
                    Highway Factory<br/> 
                    GF5H+F9H Obutu<br/>
                    Awutu Breku, Akotsi-Winneba
                  </p>
                  <a href='tel:+233534029838' className='hover:underline'>
                    +233 53 402 9838
                  </a><br/>
                  <a href='tel:+233534056390' className='hover:underline'>
                    +233 53 405 6390
                  </a><br/><br/>
                  <p className='mb-2'>
                    <b>TAKORADI BRANCH:</b><br/>
                    Adjacent Jemima Hospital, Kwesimintim
                  </p>
                  <a href='tel:+233534024528' className='hover:underline'>
                    +233 53 402 4528
                  </a><br/><br/>
                  <p className='mb-2'>
                    <b>OFAAKOR BRANCH:</b><br/>
                    Off Kasoa Swedru Road
                  </p>
                  <a href='tel:+233534077265' className='hover:underline'>
                  +233 53 407 7265
                  </a><br/><br/>
                </div>
              </div>

              {/* Address */}
              <div>
                

                {/* Business Hours */}
                <div className='md:mb-4'>
                  <h1 className='font-bold text-lg md:text-xl lg:text-3xl text-left text-gray-700'>Business Hours</h1><br/>
                  <p className='text-start text-gray-700'>
                    <span className='font-bold'>Monday - Friday:</span> 8:00 AM - 5:00 PM<br />
                    <span className='font-bold'>Saturday:</span> Closed<br />
                    <span className='font-bold'>Sunday:</span> Closed
                  </p>
                </div><br/>

                {/* Social links */}
                <div className='pb-4'>
                  <div className='text-start text-gray-700'>
                    <p className='font-bold text-lg md:text-xl lg:text-3xl pb-1 lg:pb-2'>Contact Us:</p>

                    <p> 
                      <span className='font-bold'>Email:</span> <a href='mailto:info@centralroofing.co' className='hover:underline'>
                        info@centralroofing.co
                      </a>
                    </p><br/>

                    <div className='flex justify-start space-x-4'>
                      <a 
                        href='https://wa.me/233534029838' 
                        className='hover:underline h-16 w-16'
                        target='_blank' rel='noopener noreferrer' 
                      >
                        <FontAwesomeIcon icon={faSquareWhatsapp} className='text-2xl lg:text-4xl hover:scale-110 transform duration-100 ease-in-out hover:text-green-700' />
                      </a>
                      <a 
                        href='tel:+233534029838' 
                        className='hover:underline h-16 w-16'
                        target='_blank' rel='noopener noreferrer' 
                      >
                        <FontAwesomeIcon icon={faSquarePhone} className='text-2xl lg:text-4xl hover:scale-110 transform duration-100 ease-in-out hover:text-green-700' />
                      </a>
                      <a 
                        href='mailto:info@centralroofing.co'
                        className='hover:underline h-16 w-16'
                        target='_blank' rel='noopener noreferrer' 
                      >
                        <FontAwesomeIcon icon={faEnvelope} className='text-2xl lg:text-4xl hover:scale-110 transform duration-100 ease-in-out hover:text-green-700' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
 
        </div>

        {/* Form */}
        <form ref={form} onSubmit={sendEmail} className='mt-8 md:mt-16 bg-white p-4 md:p-8 rounded-lg text-gray-700 h-fit'>
          <p className='font-semibold text-xl lg:text-2xl mb-4 text-left'>Send us a message</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4'>
            <div className=''>
              <label htmlFor="name" className='block text-left'>Name</label>
              <input 
                type="text" 
                name='name' 
                id='name' 
                className='w-full border border-gray-300 bg-white rounded-md p-2' 
                placeholder='Name' 
                required 
              />
            </div>
            <div className=''>
              <label htmlFor="email" className='block text-left'>Email</label>
              <input 
                type="email" 
                name='email' 
                id='email' 
                className='w-full border border-gray-300 bg-white rounded-md p-2' 
                placeholder='Email' 
                required 
              />
            </div>
            <div className=''>
              <label htmlFor="phone" className='block text-left'>Phone</label>
              <input 
                type="phone" 
                name='phone' 
                id='phone' 
                className='w-full border border-gray-300 bg-white rounded-md p-2' 
                placeholder='Phone' 
                required 
              />
            </div>
            <div className=''>
              <label htmlFor="subject" className='block text-left'>Subject</label>
              <input 
                type="text" 
                name='subject' 
                id='subject' 
                className='w-full border border-gray-300 bg-white rounded-md p-2' 
                placeholder='Subject' 
                required 
              />
            </div>
          </div>
          <div className='mt-2 md:mt-5'>
            <label htmlFor="message" className='block text-left'>Message</label>
            <textarea 
              name="message" 
              id="message" 
              cols="30" 
              rows="10" 
              className='w-full border border-gray-300 bg-white rounded-md p-2' 
              placeholder='Type message here' 
              required>
            </textarea>
          </div>
          <div className='mt-2 md:mt-4 lg:mt-6'>
            <button type='submit' className='bg-seablue text-white px-4 py-2 rounded-md'>Send &#129050;</button>
          </div>
        </form>
      </section>
      {/* Location map */}
      <div className=''>
            {/* Map */}
            <iframe
              title='map'
              width="100%"
              height="500"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className='border-2 border-gray-300 shadow-lg rounded-lg'
              frameborder="10" 
              marginheight="0" 
              marginwidth="0" 
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Central%20Roofing,%20Awutu%20Breku+(Central%20Roofing)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              <a href="https://www.gps.ie/">gps vehicle tracker</a>
            </iframe>
          </div>
    </div>
  )
}