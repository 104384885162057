import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import roofing1 from '../assets/images/roofing1.jpg';
import roofing2 from '../assets/images/roofing2.jpg';
import roofing3 from '../assets/images/roofing3.jpg';
import roofing4 from '../assets/images/roofing4.jpg';
import roofing5 from '../assets/images/roofing5.jpg';
import roofing6 from '../assets/images/roofing6.jpg';

export default function Carousel() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  }

  return (
    <Slider {...settings} className='w-full'>
      <div className='w-full h-[30vh] md:h-[50vh] lg:h-[70vh] xl:h-[98vh] opacity-80'>
        <img className='object-cover object-center w-full h-full' src={roofing1} alt='Roofing' />
      </div>
      <div className='w-full h-[30vh] md:h-[50vh] lg:h-[70vh] xl:h-[98vh] opacity-80'>
        <img className='object-cover object-center w-full h-full' src={roofing2} alt='Roofing' />
      </div>
      <div className='w-full h-[30vh] md:h-[50vh] lg:h-[70vh] xl:h-[98vh] opacity-80'>
        <img className='object-cover object-center w-full h-full' src={roofing3} alt='Roofing' />
      </div>
      <div className='w-full h-[30vh] md:h-[50vh] lg:h-[70vh] xl:h-[98vh] opacity-80'>
        <img className='object-cover object-center w-full h-full' src={roofing4} alt='Roofing' />
      </div>
      <div className='w-full h-[30vh] md:h-[50vh] lg:h-[70vh] xl:h-[98vh] opacity-80'>
        <img className='object-cover object-center w-full h-full' src={roofing5} alt='Roofing' />
      </div>
      <div className='w-full h-[30vh] md:h-[50vh] lg:h-[70vh] xl:h-[98vh] opacity-80'>
        <img className='object-cover object-center w-full h-full' src={roofing6} alt='Roofing' />
      </div>
    </Slider>
  )
}
