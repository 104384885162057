import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import centralRoofing from '../assets/images/centralRoofing.png';

export default function NavBar() {
  const [navbar, setNavbar] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setNavbar(!navbar);
  };

  return (
    <nav className={`sticky py-2 md:py-4 px-3 lg:px-8 top-0 left-0 z-40 bg-white`}>
        <div className="justify-between mx-auto lg:max-w-7xl md:items-center md:flex">
            <div>
                <div className="flex items-center justify-between md:block">
                    <Link to="/" className="text-2xl font-bold text-white">
                        <img
                            src={centralRoofing}
                            alt="Central Roofing Company Logo"
                            className="w-24 md:w-32"
                        />
                    </Link>
                    <div className="md:hidden">
                        <button
                            className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                            onClick={() => setNavbar(!navbar)}
                        >
                            {navbar ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div
                    className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                        navbar ? "block" : "hidden"
                    }`}
                >
                    <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                        <li className={`text-seablue hover:text-blue-300 font-medium ${location.pathname === '/' ? 'underline' : 'text-black'}`}>
                            <Link to="/" onClick={toggleNavbar}>Home</Link>
                        </li>
                        <li className={`text-seablue hover:text-blue-300 font-medium ${location.pathname === '/about' ? 'underline' : ''} ${location.pathname === '/' ? 'text-black' : 'text-black'}`}>
                            <Link to="/about" onClick={toggleNavbar}>About Us</Link>
                        </li>
                        <li className={`text-seablue hover:text-blue-300 font-medium ${location.pathname === '/contact' ? 'underline' : ''} ${location.pathname === '/' ? 'text-black' : 'text-black'}`}>
                            <Link to="/contact" onClick={toggleNavbar}>Contact Us</Link>
                        </li>
                        <li className={`text-seablue hover:text-blue-300 font-medium ${location.pathname === '/services' ? 'underline' : ''} ${location.pathname === '/' ? 'text-black' : 'text-black'}`}>
                            <Link to="/services" onClick={toggleNavbar}>Services</Link>
                        </li>
                        <li className={`text-seablue hover:text-blue-300 font-medium ${location.pathname === '/projects' ? 'underline' : ''} ${location.pathname === '/' ? 'text-black' : 'text-black'}`}>
                            <Link to="/projects" onClick={toggleNavbar}>Projects</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
  )
}
