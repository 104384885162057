import React, { useState } from 'react';

export default function FAQItem({question, answer}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className="mb-4">
      <div
        className="cursor-pointer text-seablue hover:underline text-center"
        onClick={toggleOpen}
      >
        {question}
      </div>
      {isOpen && (
        <div className="mt-2 text-gray-700 text-justify">
          <p>{answer}</p>
        </div>
      )}
    </div>
  )
}
