import React from 'react';
import FAQItem from './FAQItem';

export default function FAQSection() {
  const faqData = [
    {
      question: 'What types of roofing services do you offer?',
      answer: 'Central Roofing Systems provides a wide range of roofing services, including roof installations, repairs, replacements, and maintenance for both residential and commercial properties. We specialize in single ply roofing, modified roofing, built-up roofing, metal roofing, vegetative roofing, and specialty metal roofing.',
    },
    {
      question: 'How experienced is your roofing team?',
      answer: 'Our roofing team comprises highly skilled and experienced professionals with a collective expertise of over 50 years in the industry. They have successfully handled numerous roofing projects of varying complexities, ensuring each job is completed with precision and attention to detail.',
    },
    {
      question: 'Are you licensed and insured?',
      answer: "Yes, Central Roofing Systems is fully licensed and insured. We prioritize the safety of our team, clients, and property during every roofing project, giving you peace of mind knowing you're working with a reputable and responsible roofing company." ,
    },
    {
      question: 'Do you offer free estimates?',
      answer: 'Absolutely! We provide free estimates for all roofing projects. Our team will assess your roofing needs, discuss your requirements, and provide a detailed estimate with no obligations.',
    },
    {
      question: "What materials do you use for roofing?",
      answer: "At Central Roofing Systems, we prioritize quality materials that ensure durable and long-lasting roofs. We source our roofing products from reputable manufacturers, offering a wide selection of materials, styles, and colors to suit your preferences and budget."
    },
    {
      question: "How do I know if my roof needs repair or replacement?",
      answer: "If you notice signs of leaks, water stains, missing shingles, or visible damage to your roof, it may be time for repairs or a replacement. Our experts can conduct a thorough inspection and advise you on the best course of action to ensure your roof's integrity."
    },
    {
      question: "Are you environmentally friendly?",
      answer: "Yes, Central Roofing Systems is committed to sustainability. We actively seek eco-friendly roofing solutions and materials that reduce environmental impact while providing energy-efficient and green roofing options."
    },
    {
      question: "Can you handle emergency roofing repairs?",
      answer: "Absolutely! We understand that roofing emergencies can happen at any time. Our team is available to address urgent repair needs promptly and efficiently to protect your property and belongings."
    },
    {
      question: "Do you offer warranties on your roofing services?",
      answer: "Yes, we stand behind the quality of our work and the materials we use. Central Roofing Systems offers warranties on our roofing services to provide you with added protection and peace of mind."
    },
    {
      question: "How can I schedule a consultation or request roofing services?",
      answer: "Scheduling a consultation or requesting our roofing services is easy! Simply contact us via phone or email, and our friendly team will be happy to assist you. We'll discuss your roofing needs, answer any questions you have, and guide you through the process. Have a question that's not listed here? Feel free to reach out to us, and we'll be glad to provide you with the information you need. At Central Roofing Systems, we are here to be your trusted roofing partner!"
    }
  ];

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-xl md:text-2xl lg:text-4xl font-semibold mb-4 lg:mb-8 text-gray-700">Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  )
}
